@use '_mixins' as *;
@use '_variables' as *;
@use 'sass:map';
@import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';

// style for gantt container to fill container
.gantt-component {
  height: 100%;
  display: flex;
  flex-direction: column;
  & > boiler-gantt {
    flex-grow: 1;
  }
}

.no-border {
  .gantt_row_task,
  .gantt_task_row {
    border: none !important;
  }
}

.gantt-chart {
  position: relative;
  width: 100%;
  height: 100%;
  border-color: map.get($color, gantt-lines);
  .gantt_container {
    background: inherit;
  }
  .gantt_row,
  .gantt_task_row,
  .gantt_grid_scale,
  .gantt_task_scale {
    background: inherit !important;
  }
  .gantt_grid_scale,
  .gantt_task_scale,
  .gantt_scale_line {
    border-color: map.get($color, gantt-lines);
  }
  .gantt_task_line > .gantt_task_content {
    overflow: visible;
  }
}

.gantt_scale_line:nth-child(1) {
  height: 2.938rem !important;
  .gantt_scale_cell {
    height: 2.938rem !important;
    line-height: 2.938rem;
  }
}
.gantt_scale_line:nth-child(2) {
  overflow: hidden;
  height: 2.45rem !important;
  .gantt_scale_cell {
    height: 2.49rem !important;
    line-height: 2.49rem;
  }
}
.gantt_layout_root {
  border: none;
}
.gantt-chart ::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  overflow-x: visible;
}
.gantt-chart ::-webkit-scrollbar-thumb {
  background: map.get($color, border-disable);
  border-radius: 1.25rem;
  height: 13.18rem;
}

.gantt_task_cell {
  border-right: 0rem;
}

.gantt_tree_icon.gantt_file {
  display: none;
}

.gantt_grid_head_cell {
  width: 100%;
  display: flex;
}

.gantt_tree_content {
  font-size: map.get($font-size, large);
  font-weight: map.get($font-weight, bold);
  font-family: var(--font-family-primary);
  color: map-get($color, grid-text);
  width: 100%;
  .column-container {
    width: 100%;
    height: 100%;
    display: flex;
  }
}

.gantt_scale_line {
  font-size: 0.813rem;
  font-weight: map.get($font-weight, bold);
  font-family: var(--font-family-primary);
}

.gantt_task .gantt_task_scale .gantt_scale_cell {
  color: map-get($color, grid-text);
  overflow: hidden;
  text-overflow: ellipsis;
}

.gantt_task_line.gantt_bar_task {
  border: none !important;
  border-radius: 0.5rem !important;
}

.weekend {
  color: map.get($color, primary) !important;
  background-color: map.get($color, menu-item-active);
  border-right: 0.063rem solid #e5e5e5 !important;
}

.overallocated {
  background-color: map.get($color, warning-banner) !important;
  color: map.get($color, overallocated);
  font-size: map.get($font-size, default) !important;
  font-weight: map.get($font-weight, bold) !important;
  font-family: var(--font-family-primary) !important;
  text-align: left;
  border-radius: 0.5rem !important;
  padding-left: 1rem !important;
}

.overallocated-rate {
  float: right;
  margin: 0.2rem 0.5rem;
  border-radius: 0.656rem;
  padding: 0.063rem 0.625rem;
  padding-bottom: -0.2rem;
  height: 1.75rem;
  background: map.get($color, light);
  font-weight: map.get($font-weight, light);
  color: map.get($color, overallocated-resource-gantt-text);
  font-size: map.get($font-size, medium);
  display: inline-flex;
  align-items: center;
}

.overallocated-hours {
  font-weight: map.get($font-weight, lightest);
  color: map.get($color, warning);
  font-size: 0.75rem;
}

.up-arrow-icon {
  margin-left: 0.938rem;
  font-size: map-get($font-size, largest);
}

.before-closed-won {
  background: linear-gradient(
      rgba(165, 214, 243, 0.451),
      rgba(165, 215, 243, 0.45)
    ),
    url('../../assets/images/Rectangle.jpg') !important;
  text-align: left;
  padding-left: 1rem;
  color: map.get($color, actual-resource-gantt-text);
  border-radius: 0.5rem !important;
  font-size: map.get($font-size, default) !important;
  font-weight: map.get($font-weight, bold) !important;
  font-family: var(--font-family-primary) !important;
}

.e-headercell > .e-headercelldiv {
  overflow: visible;
}
hr {
  margin: 0;
  margin-left: -2rem;
  margin-right: -1rem;
}

.gantt-search {
  nb-form-field {
    max-width: initial !important;
    width: 100%;
  }
  .nb-form-control-container {
    input {
      border: none;
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-size: map.get($font, medium) !important; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-size: map.get($font, medium) !important;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        font-size: map.get($font, medium) !important;
      }
    }
  }
}

.today {
  background-color: map.get($color, primary) !important;
}

.gantt-menu-overlay {
  width: fit-content;
  height: fit-content;
}

.gantt-menu-item {
  font-size: map.get($font-size, default) !important;
  font-weight: map.get($font-weight, bold) !important;
  font-family: var(--font-family-primary) !important;
  color: map.get($color, dark);
}

.actual_resource_bar {
  display: flex !important;
  background-color: #ebf7fe;
  border: 0.063rem solid map.get($color, actual-resource-gantt-background);
  border-radius: 0.5rem;
  height: 2.188rem;
}

.billing_rate {
  margin-left: calc(100% - 120px);
  font-size: map.get($font-size, medium) !important;
  font-weight: map.get($font-weight, light) !important;
  font-family: var(--font-family-primary) !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.125rem 0.75rem;
  gap: 0.625rem;
  width: 3.688rem;
  height: 1.188rem;
  background: map.get($color, light);
  border-radius: 0.656rem;
  margin-top: 0.5rem;
  color: map.get($color, actual-resource-gantt-text);
}

.actual_resource_hours {
  text-align: left;
  margin-left: 1rem;
  font-family: var(--font-family-primary);
  font-weight: map.get($font-weight, bold);
  font-size: map.get($font-size, default);
  color: map.get($color, actual-resource-gantt-text);
}

.parent_resource_bar {
  height: 2.188rem;
  background: map.get($color, gantt-parent-bar);
  border: 0.063rem solid map.get($color, gantt-parent-bar-border);
  border-radius: 0.5rem;
}

.gantt_layout_cell .scrollVer_cell {
  width: 0rem !important;
}

.gantt_tree_icon {
  width: 0rem !important;
}
